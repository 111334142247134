<template>
    <el-dialog
        :title="mode == 'addUser' ? '添加用户' : '用户详情'"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible" width="800px">
        <div class="user-operate-box">
            <el-form ref="form" :inline="true" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="用户姓名" prop="username">
                    <el-input v-model="form.username" placeholder="请输入用户姓名" size="small" :disabled="mode=='lookUser'"></el-input>
                </el-form-item>
                <el-form-item label="用户性别" prop="sex">
                    <el-radio-group v-model="form.sex" :disabled="mode=='lookUser'">
                        <el-radio label="男"></el-radio>
                        <el-radio label="女"></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="出生年月">
                    <el-date-picker
                        :disabled="mode=='lookUser'"
                        v-model="form.birthday"
                        type="date"
                        size="small"
                        placeholder="选择日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="所属部门" prop="deptId">
                    <el-select v-model="form.deptId" placeholder="请选择所属部门" size="small" :disabled="mode=='lookUser'">
                        <el-option v-for="(item, index) in option" :key="index" :label="item.name" :value="item.id" 
                        v-show="item.name!='未分配部门'"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号码" prop="phone">
                    <el-input v-model="form.phone" placeholder="请输入手机号码" size="small" :disabled="mode=='lookUser'"></el-input>
                </el-form-item>
                <el-form-item label="电子邮箱" prop="email">
                    <el-input v-model="form.email" placeholder="请输入电子邮箱" size="small" :disabled="mode=='lookUser'"></el-input>
                </el-form-item>
            </el-form>
            <div v-if="mode!='lookUser'">
                <p class="tip-text">说明：用户的密码系统自动生成密码，生成初始密码格式为：用户姓名首字母+手机号码后6位；</p>
                <p class="tip-text">举例：用户姓名：张三，手机号码：13483680549；初始密码为：zs680549</p>
            </div>
        </div>
        <span slot="footer" class="dialog-footer" v-if="mode!='lookUser'">
            <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" size="small" @click="save">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { manageAPI } from 'liankong-ui-api'
export default {
    props: ['access_token'],
    data() {
        // 手机号
        var validatePhoneNumber = (rule, value, callback) => {
            if (value) {
                var length = value.length;
                var phone = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                if (length == 11 && phone.test(value)) {
                    callback()
                } else {
                    callback(new Error('请填写正确的11位手机号'))
                }
            }
        }
        return {
            dialogVisible: false,
            mode: 'addUser',
            form: {
                username: '',
                sex: '',
                birthday: '',
                deptId: '',
                phone: '',
                email: ''
            },
            rules: {
                username: [
                    { required: true, message: '请输入用户姓名', trigger: 'blur' },
                ],
                sex: [
                    { required: true, message: '请选择用户性别', trigger: 'change' }
                ],
                deptId: [
                    { required: true, message: '请选择所属部门', trigger: 'change' }
                ],
                phone: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { validator: validatePhoneNumber, trigger: 'blur' }
                ],
                email: [
                    { required: false, message: '请输入电子邮箱', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                ]
            },
            option: []
        }
    },
    methods: {
        show(type, row, orgStructureTree) {
            this.dialogVisible = true;
            this.mode = type;
            this.option = orgStructureTree[0].children
            if(type == 'addUser') {
                this.form = {
                    username: '',
                    sex: '',
                    birthday: '',
                    deptId: '',
                    phone: '',
                    email: ''
                }
            } else {
                manageAPI.toolGetUserDetail({userId: row.userId, orgId: orgStructureTree[0].id}, this.access_token).then(res=>{
                    if(res.code == 0) {
                        this.form = {
                            username: res.data.username,
                            sex: res.data.sex == 1 ? '男' : '女',
                            birthday: res.data.birthday,
                            deptId: '',
                            phone: res.data.mobile,
                            email: res.data.email
                        }

                        this.option.forEach(items=>{
                            if(items.name == res.data.groupName) {
                                this.form.deptId = items.id
                            }
                        })
                       
                    }
                })
            }
            this.$refs['form'] ? this.$refs['form'].resetFields() : '';
        },
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if(this.mode == 'addUser') {
                        manageAPI.toolAddDepPerson(this.form, this.access_token).then(res=>{
                            if(res.code == 0) {
                                this.$message.success('添加成功');
                                // 刷新列表
                                this.$emit('reloadData');
                                this.dialogVisible = false;
                            } else {
                                this.$message.error(res.message);
                            }
                        }).catch(error=>{
                            this.$message.error(error.message);
                        })
                    }
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.el-dialog {
    .el-dialog__body {
        .user-operate-box {
            width: 92%;
            margin: 0 auto;
            .el-form {
                .el-form-item {
                    width: 48%;
                    margin-right: 0;
                    .el-form-item__content {
                        .el-input, .el-select {
                            width: 220px;
                        }
                        .el-radio-group {
                            width: 220px;
                            text-align: left;
                        }
                    }
                }
                .el-form-item:nth-child(2n) {
                    text-align: right;
                }
            }
            .tip-text{
                color: red;
                margin-bottom: 5px;
            }
        }
       
    }
}
</style>