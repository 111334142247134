<template>
    <div>
        <el-dialog
        title="重置密码"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="40%">
        <div>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="用户姓名">
                    <el-input v-model="form.name" placeholder="请输入用户姓名" size="small" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="重置密码" prop="password">
                    <el-input type="password" v-model="form.password" autocomplete="off" placeholder="密码格式数字、大小写英文，8位数以上" size="small"></el-input>
                </el-form-item>

                <div class="password-mj" style="margin-bottom:10px;" v-show="showSetOpenPassWordGrade">
                    安全等级 &nbsp; &nbsp;
                    <span :class="'span'+setOpenPassWordGrade" v-for="(item,index) in 5" :key="index"></span>
                    &nbsp; &nbsp; {{ setOpenPassWordGrade==1?'弱': setOpenPassWordGrade==3?'中':'强' }}
                </div>

                <el-form-item label="确认密码" prop="surePassword">
                    <el-input type="password" v-model="form.surePassword" autocomplete="off" placeholder="请再次输入确认密码" size="small"></el-input>
                </el-form-item>

                <div class="password-mj" v-show="showConfirmOpenPassWordGrade">
                    安全等级 &nbsp;
                    <span :class="'span'+confirmOpenPassWordGrade" v-for="(item,index) in 5" :key="index"></span>
                    &nbsp; &nbsp; {{ confirmOpenPassWordGrade==1?'弱': confirmOpenPassWordGrade==3?'中':'强' }}
                </div>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" size="small" @click="save">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>
<script>
import { manageAPI } from 'liankong-ui-api'
export default {
    props: ['access_token'],
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                /* 不显示安全等级*/
                this.showSetOpenPassWordGrade = false;

                callback(new Error('请输入密码'));
            } else {
                /* 显示安全等级*/
                this.showSetOpenPassWordGrade = true;
                if (this.passwordRules(this.form.password)==4) {
                    /*赋值安全等级 高*/
                    this.setOpenPassWordGrade = 5
                } else if (this.passwordRules(this.form.password)>2 && this.passwordRules(this.form.password)<4) {
                    /*赋值安全等级 中*/
                    this.setOpenPassWordGrade = 3
                } else if (this.passwordRules(this.form.password)<3) {
                    /*赋值安全等级 低*/
                    this.setOpenPassWordGrade = 1
                }

                if (this.form.surePassword !== '') {
                    this.$refs.form.validateField('surePassword');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                /* 不显示安全等级*/
                this.showConfirmOpenPassWordGrade = false;

                callback(new Error('请再次输入密码'));
            } else if (value !== this.form.password) {
                /* 不显示安全等级*/
                this.showConfirmOpenPassWordGrade = false;

                callback(new Error('两次输入密码不一致!'));
            } else {
                /* 显示安全等级*/
                this.showConfirmOpenPassWordGrade = true;
                if (this.passwordRules(this.form.surePassword)==4) {
                    /*赋值安全等级 高*/
                    this.confirmOpenPassWordGrade = 5
                } else if (this.passwordRules(this.form.surePassword)>2 && this.passwordRules(this.form.surePassword)<4) {
                    /*赋值安全等级 中*/
                    this.confirmOpenPassWordGrade = 3
                } else if (this.passwordRules(this.form.surePassword)<3) {
                    /*赋值安全等级 低*/
                    this.confirmOpenPassWordGrade = 1
                }

                callback();
            }
        };
        return {
            dialogVisible: false,
            form: {
                name: '',
                password: '', // 传递给后台
                surePassword: '',
                userId: '', // 传递给后台
                orgId: '', // 传递给后台
            },
            rules: {
                password: [
                    { required: true, validator: validatePass, trigger: 'blur' },
                    { min: 8, message: '密码长度在8位以上', trigger: 'blur' }
                ],
                surePassword: [
                    { required: true, validator: validatePass2, trigger: 'blur' },
                    { min: 8, message: '密码长度在8位以上', trigger: 'blur' }
                ]
            },
            showSetOpenPassWordGrade: false,
            setOpenPassWordGrade: '', // 设置校验安全等级
            showConfirmOpenPassWordGrade: false,
            confirmOpenPassWordGrade: '', // 确认密码校验安全等级
        }
    },
    methods: {
        show(row, orgStructureTree) {
            this.dialogVisible = true;
            this.form = {
                name: row.username,
                password: '',
                surePassword: '',
                userId: row.userId,
                orgId: orgStructureTree[0].id
            }

            this.$refs['form'] ? this.$refs['form'].resetFields() : '';
        },
        // 密码安全等级判断
        passwordRules(password){
            let passwordCount = 0
            const smallLetter = /[a-z]/; //小写字母
            const bigLetter = /[A-Z]/; //大写字母
            const digit = /[0-9]/; // 数字
            const specialLetter = /[._~!@#$^&*]/; // 特殊字母
            if(password.match(specialLetter)) {
                passwordCount++
            }
            if(password.match(smallLetter)){
                passwordCount++
            }
            if(password.match(bigLetter)){
                passwordCount++
            }
            if(password.match(digit)){
                passwordCount++
            }
            return passwordCount
        },
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    manageAPI.toolEditPassword({
                        userId: this.form.userId,
                        password: this.form.password,
                        orgId: this.form.orgId
                    }, this.access_token).then(res=>{
                        if(res.code == 0) {
                            this.$message.success('重置密码成功');
                        } else {
                            this.$message.error(res.message);
                        }
                        this.dialogVisible = false;
                    }).catch(error=>{
                        this.$message.error(error.message);
                        this.dialogVisible = false;
                    })
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.el-dialog {
    .el-dialog__body {
        .el-form {
            .password-mj {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 13px;
                span {
                    display: inline-block;
                    width: 20px;
                    height: 10px;
                    margin: 0 1px;
                }
                .span1 {
                    background: #e6a23c;
                }
                .span3 {
                    background: #67c23a;
                }
                .span5 {
                    background: #f56c6c;
                }
            }
        }
    }
}
</style>