<template>
    <div>
        <el-dialog
        :title="mode == 'addDep' ? '添加部门' : '修改部门'"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="40%">
        <div>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="部门名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入要添加的部门名称" size="small"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" size="small" @click="save">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>
<script>
import { manageAPI } from 'liankong-ui-api'
export default {
    props: ['access_token'],
    data() {
        return {
            dialogVisible: false,
            mode: 'addDep',
            form: {
                name: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入部门名称', trigger: 'blur' },
                ]
            }
        }
    },
    methods: {
        show(type, orgStructureTree, currentStructureNodeData) {
            this.dialogVisible = true;
            this.mode = type;
            if(type == 'addDep') {
                // 传入组织id
                this.form = {
                    organizationId: orgStructureTree[0].id,
                    parentId: 0,
                    name: ''
                }
                delete this.form.id;
            } else {
                this.form = {
                    name: currentStructureNodeData.name,
                    id: currentStructureNodeData.id // 部门id
                }
            }
            this.$refs['form'] ? this.$refs['form'].resetFields() : '';
        },
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if(this.mode == 'addDep') {
                        manageAPI.toolCreateDep(this.form, this.access_token).then(res=>{
                            if(res.code == 0) {
                                this.$message.success('添加成功');
                                // 刷新列表
                                this.$emit('reloadList');
                            } else {
                                this.$message.error(res.message);
                            }
                            this.dialogVisible = false;
                        }).catch(error=>{
                            this.$message.error(error.message);
                            this.dialogVisible = false;
                        })
                    } else {
                        manageAPI.toolEditDep(this.form, this.access_token).then(res=>{
                            if(res.code == 0) {
                                this.$message.success('修改成功');
                                // 刷新列表
                                this.$emit('reloadList');
                            } else {
                                this.$message.error(res.message);
                            }
                            this.dialogVisible = false;
                        }).catch(error=>{
                            this.$message.error(error.message);
                            this.dialogVisible = false;
                        })
                    }
                   
                }
            });
        }
    }
}
</script>