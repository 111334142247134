<template>
    <div class="organizational-page page-container">
        <span>组织管理</span>
        <div class="organizational-content">
            <div class="organizational-left">
                <h4>
                    <i class="el-icon-office-building"></i>
                    {{ orgStructureTree.length == 0 ? '组织管理' : orgStructureTree[0].name }}
                </h4>
                <div class="organizational-btn">
                    <el-button type="text" icon="el-icon-circle-plus-outline" @click="handleBtnEvent('addDep')">创建</el-button>
                    <el-button type="text" icon="el-icon-edit-outline" @click="handleBtnEvent('editDep')" :disabled="(orgStructureTree.length>0 && !orgStructureTree[0].children[0].id) || (currentStructureNodeData && !currentStructureNodeData.organizationId)">重命名</el-button>
                    <el-button type="text" icon="el-icon-delete" @click="handleBtnEvent('removeDep')" :disabled="(orgStructureTree.length>0 && !orgStructureTree[0].children[0].id) || (currentStructureNodeData && !currentStructureNodeData.organizationId)">删除</el-button>
                </div>
                <div class="mytree">
                    <el-tree
                        :data="orgStructureTree"
                        default-expand-all
                        node-key="id"
                        ref="tree"
                        highlight-current
                        :props="defaultProps"
                        :current-node-key="currentStructureNodeKey"
                        @current-change="handleOrgManagerChange">
                        <span class="text-box" slot-scope="{ node, data }">
                            <!-- 不显示未分配部门 -->
                            <i class="el-icon-folder-opened" style="margin-right: 3px;" v-if="data.id"></i>
                            <span class="org-title" :title="data.name" v-if="!data.organizationId">组织机构</span>
                            <span class="org-title" :title="data.name" v-else >{{data.name}}</span>
                        </span>
                    </el-tree>
                </div>
            </div>
            <div class="organizational-right">
                <div class="organizational-search">
                    <div>
                        <el-button type="primary" :disabled="orgStructureTree.length == 0 || (currentStructureNodeData && !currentStructureNodeData.organizationId)" size="small" icon="el-icon-circle-plus-outline" @click="handleBtnEvent('addUser')">添加新用户</el-button>
                        <el-upload
                            :disabled="orgStructureTree.length == 0  || (currentStructureNodeData && !currentStructureNodeData.organizationId)"
                            class="upload-demo"
                            action="none"
                            accept=".xls,.xlsx"
                            :auto-upload="false"
                            :on-change="onUploadChanged"
                            :show-file-list="false">
                            <el-button type="primary" size="small" icon="el-icon-upload2" :disabled="orgStructureTree.length == 0 || (currentStructureNodeData && !currentStructureNodeData.organizationId)">导入通讯录</el-button>
                        </el-upload>
                        <el-button type="primary" size="small" icon="el-icon-download" @click="handleBtnEvent('download')" :disabled="orgStructureTree.length == 0">下载导入模板</el-button>
                        <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" @click="handleBtnEvent('systemUser')" :disabled="orgStructureTree.length == 0 || (currentStructureNodeData && !currentStructureNodeData.organizationId)">添加系统用户</el-button>
                    </div>
                    <el-form :inline="true" :model="formInline" class="demo-form-inline">
                        <el-form-item label="用户名称">
                            <el-input v-model="formInline.name" placeholder="请输入用户名称" size="small"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="small" @click="onSubmit" :disabled="orgStructureTree.length == 0">搜索</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="table-box">
                    <el-table :data="tableData" style="width:100%;" empty-text="暂无数据">
                        <el-table-column
                        v-for="item in tableColumn" :key="item.label"
                            :type="item.type"
                            :prop="item.prop"
                            :label="item.label"
                            :width="item.width ? item.width : 'auto'"
                            :align="item.align">
                        </el-table-column>
                        <el-table-column
                        label="组织身份"
                        align="center">
                            <template slot-scope="scope">
                                <span>{{scope.row.userId == currentStructureNodeData.owner ? '租户管理员' : '成员'}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="所属部门"
                        align="center">
                            <template slot-scope="scope">
                                <span v-if="currentStructureNodeData.organizationId">{{ currentStructureNodeData.name }}</span>
                                <span v-else>{{ scope.row.groupName ? scope.row.groupName : '--' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="操作"
                            align="center"
                            width="200">
                                <template slot-scope="scope">
                                    <el-button type="text" size="small" @click="handleBtnEvent('lookUser', scope.row)">详情</el-button>
                                    <el-button type="text" size="small" @click="handleBtnEvent('resetPassword', scope.row)">重置密码</el-button>
                                    <el-button type="text" size="small" class="remove-btn" @click="handleBtnEvent('removeUser', scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange"
                        :current-page="formInline.pageNum"
                        :page-size="formInline.pageSize"
                        :total="total">
                    </el-pagination>
                </div>
               
            </div>
        </div>

        <!-- 添加部门弹框 -->
        <DepItem ref="depDialog" :access_token="access_token" @reloadList="getList(false)"></DepItem>
        <!-- 添加用户弹框 -->
        <UserItem ref="userDialog" :access_token="access_token" @reloadData="reloadData"></UserItem>
        <!-- 重置密码弹框 -->
        <ResetPassword ref="passwordDialog" :access_token="access_token" ></ResetPassword>
        <!-- 添加系统用户 -->
        <AddUserDialog ref='AddUserDialog' @reloadData="reloadData"></AddUserDialog>
    </div>
</template>
<script>
import { manageAPI ,groupAPI } from 'liankong-ui-api'
import { Store } from '@/utils/LocalCache.js'
import DepItem from './components/DepItem.vue'
import UserItem from './components/UserItem.vue'
import ResetPassword from './components/ResetPassword.vue'
import AddUserDialog from './components/AddUserDialog.vue'
export default {
    components:{
        DepItem,
        UserItem,
        ResetPassword,
        AddUserDialog
    },
    data() {
        return {
            access_token: null, // 获取token
            orgStructureTree: [], // 组织列表数据
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            currentStructureNodeKey: '', // 组织tree选中标识
            currentStructureNodeData: null, // 组织tree选中信息
            formInline: {
                id: "",
                name: "",
                pageNum: 1,
                pageSize: 10
            },
            tableColumn: [
                { label: '序号', type: 'index', width: 80, align: 'center' },
                { label: '用户名称', prop: 'username', align: 'center' },
                { label: '手机号码', prop: 'mobile', align: 'center' },
                { label: '创建日期', prop: 'joinDate', align: 'center' }
            ],
            tableData: [], // 成员列表数据
            total: 0
        }
    },
    
    mounted() {
        this.access_token = Store.get('access_token')
        this.getList(true);
    },
    methods: {
        // 获取组织列表
        getList(flag) {
            manageAPI.toolGetOrgList(this.access_token).then(res=>{
                if(res.code == 0) {
                    this.orgStructureTree = res.data;
                    
                    // 默认选中根组织
                    if(this.orgStructureTree.length!=0) {
                        if(flag) {
                            this.handleOrgManagerChange(this.orgStructureTree[0])
                        } else {
                            const newCurrentStructureNodeData = this.currentStructureNodeData ? this.orgStructureTree[0].children.filter(item=>item.id == this.currentStructureNodeData.id) : []
                            this.handleOrgManagerChange(newCurrentStructureNodeData.length>0 ? newCurrentStructureNodeData[0] : this.orgStructureTree[0])
                        }
                    }
                } else {
                    this.$message.error(res.message);
                }
            })
        },
    
        // 当前选中节点变化时触发的事件
        handleOrgManagerChange(node, data) {
            this.currentStructureNodeKey = node.id;
            this.formInline.pageNum = 1;
            this.currentStructureNodeData = node;
            this.$nextTick(()=>{
                if(this.$refs.tree) {
                    this.$refs.tree.setCurrentKey(this.currentStructureNodeKey);
                } 
            })

            if(node.organizationId) { // 选中的是部门
                this.getDepPerson()
            } else { // 选中的是组织
                this.getOrgPerson()
            }

            // 清空搜索框的值
            this.formInline.name = '';
        },
        // 获取部门成员
        getDepPerson() {
            this.formInline.id = this.currentStructureNodeData.id;
            manageAPI.toolDepMemberList(this.formInline, this.access_token).then(res=>{
                if(res.code == 0) {
                    this.tableData = res.data;
                    this.total = res.total;
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        // 获取组织下所有成员
        getOrgPerson() {
            this.formInline.id = this.currentStructureNodeData.id;
            manageAPI.toolOrgMemberList(this.formInline, this.access_token).then(res=>{
                if(res.code == 0) {
                    this.tableData = res.data;
                    this.total = res.total;
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        // 搜索
        onSubmit() {
            this.formInline.pageNum = 1;
            if(this.currentStructureNodeData.organizationId) {
                this.getDepPerson()
            } else {
                this.getOrgPerson()
            }
        },
        // 分页
        handleCurrentChange(num) {
            this.formInline.pageNum = num;
            if(this.currentStructureNodeData.organizationId) {
                this.getDepPerson()
            } else {
                this.getOrgPerson()
            }
        },
        // 添加用户回调
        reloadData() {
            this.formInline.pageNum = 1;
            if(this.currentStructureNodeData.organizationId) {
                this.getDepPerson()
            } else {
                this.getOrgPerson()
            }
            // this.handleOrgManagerChange(this.orgStructureTree[0])
        },
        // 按钮操作
        handleBtnEvent(type, row) {
            switch(type) {
                case 'addDep': 
                    this.$nextTick(()=>{
                        this.$refs.depDialog.show(type, this.orgStructureTree, this.currentStructureNodeData);
                    })
                break;

                case 'editDep':
                    this.$nextTick(()=>{
                        this.$refs.depDialog.show(type, this.orgStructureTree, this.currentStructureNodeData);
                    })
                break;

                case 'removeDep':
                    this.$confirm('确定要删除当前选择的部门吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        manageAPI.toolRemoveDep(this.currentStructureNodeData.id, this.access_token).then(res=>{
                            if(res.code == 0) {
                                this.$message.success('删除成功');
                                this.getList(false);
                            } else {
                                this.$message.error(res.message);
                            }
                        })
                    }).catch(() => {});
                break;

                case 'addUser':
                    this.$nextTick(()=>{
                        this.$refs.userDialog.show(type, row, this.orgStructureTree);
                    })
                break;

                case 'lookUser':
                    this.$nextTick(()=>{
                        this.$refs.userDialog.show(type, row, this.orgStructureTree);
                    })
                break;

                case 'resetPassword':
                    this.$nextTick(()=>{
                        this.$refs.passwordDialog.show(row, this.orgStructureTree);
                    })
                break;

                case 'removeUser':
                    this.$confirm('确定要删除当前选择的用户吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        groupAPI.removeOrgMembers({
                            id: this.orgStructureTree[0].id, 
                            userId: row.userId
                        }).then(res=>{
                            if(res.code == 0) {
                                this.$message.success('删除成功');
                                this.formInline.pageNum = 1;
                                if(this.currentStructureNodeData.organizationId) {
                                    this.getDepPerson()
                                } else {
                                    this.getOrgPerson()
                                }
                            } else {
                                this.$message.error(res.message);
                            }
                        }).catch(error=>{
                            this.$message.error(error.message);
                        })
                       
                    }).catch(() => {});
                break;

                case 'download':
                    window.open(this.$options.filters['baseUrl'](`/api/v1/usercenter/organization/exportOrgTemplate?access_token=${this.access_token}`)) 
                break;  

                case 'systemUser':
                    this.$refs.AddUserDialog.dialogVisible = true;
                    this.$nextTick(()=>{
                        this.$refs.AddUserDialog.init( this.currentStructureNodeData)
                    })
                  
                    break;

            }
        },
        // 导入通讯录
        onUploadChanged(file, list){
            //获取中文件后缀
            const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            const whiteList = ['xls','xlsx'];
            if (whiteList.indexOf(fileSuffix) === -1) {
                this.$message.error('上传文件只能是 xls、xlsx格式');
                return false;
            }

            let orgId = this.orgStructureTree[0].id
            var uploadFormData = new FormData()
            uploadFormData.append("file", file.raw)
            uploadFormData.append("orgId", orgId)

            manageAPI.toolExportUploadOrg(uploadFormData, this.access_token).then(res=>{
                if(res.code == 0) {
                    this.$message.success('导入成功');
                    if(this.currentStructureNodeData.organizationId) {
                        this.getDepPerson()
                    } else {
                        this.getOrgPerson()
                    }
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        }

    }
}
</script>
<style lang="less" scoped>
.organizational-page {
    .remove-btn {
        color: var(--danger-color);
    }
    .organizational-content {
        display: flex;
        margin-top: 30px;
        .organizational-left {
            width: 16%;
            min-height: 600px;
            border: 1px solid #eee;
            h4 {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 15px 0;
                text-align: center;
                background: #669;
                color: #fff;
                margin: 0;
                // background: linear-gradient(157deg, rgba(167,102,160,0.12), rgba(2,111,184,0.12));
                i {
                    font-size: 23px;
                    margin-right: 5px;
                }
            }
            .organizational-btn {
                display: flex;
                justify-content: space-between;
                padding: 5px 10px;
            }
            /deep/ .mytree {
                .el-tree > .el-tree-node:after {
                    border-top: none;
                }
                .el-tree-node {
                    position: relative;
                    padding-left: 21px;
                    margin: 10px 0;
                }
                //节点有间隙，隐藏掉展开按钮就好了,如果觉得空隙没事可以删掉
                .el-tree-node__expand-icon.is-leaf{
                    display: none;
                }
                .el-tree-node__children {
                    padding-left: 12px;
                    .el-tree-node__content {
                        display: flex!important;
                        padding-left: 10px !important;
                    }
                }
                .el-tree-node :last-child:before {
                    height: 38px;
                }
                .el-tree > .el-tree-node:before {
                    border-left: none;
                }
                .el-tree > .el-tree-node:after {
                    border-top: none;
                }
                .el-tree-node:before {
                    content: "";
                    left: -4px;
                    position: absolute;
                    right: auto;
                    border-width: 1px;
                }
                .el-tree-node:after {
                    content: "";
                    left: -4px;
                    position: absolute;
                    right: auto;
                    border-width: 1px;
                }
                .el-tree-node:before {
                    border-left: 1px dashed #4386c6;
                    bottom: 0px;
                    height: 100%;
                    top: -26px;
                    width: 1px;
                }
                .el-tree-node:after {
                    border-top: 1px dashed #4386c6;
                    height: 20px;
                    top: 12px;
                    width: 24px;
                }
                .el-tree-node:last-child {
                    display: none;
                }
                .el-tree {
                    padding: 0 12px;
                    background: none;
                    .el-tree-node {
                        .text-box {
                            display: flex;
                            align-items: center;
                        }
                        .org-title {
                            display: block;
                            font-size: 14px;
                            width: 124px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .el-icon-folder-remove {
                            font-size: 20px;
                            color: #409EFF;
                        }
                        .expanded.el-tree-node__expand-icon.el-icon-caret-right, .el-tree-node__expand-icon.el-icon-caret-right{
                            font-size: 20px;
                            color: #303133;
                            margin-left: -30px;
                        }
                    }
                }
            }
        }
        .organizational-right {
            width: 84%;
            margin-left: 30px;
            .organizational-search {
                display: flex;
                justify-content: space-between;
                >div {
                    display: flex;
                    align-items: flex-start;
                }
                .el-button {
                    // background: linear-gradient(157deg, rgba(167,102,160,0.1), rgba(2,111,184,0.1));
                    // border: 1px solid;
                    // border-radius: 7px;
                    // color: #026FB8;
                    margin-right: 20px;
                }
                // .el-button:hover {
                //     background: linear-gradient(157deg, #A766A0, #026FB8);
                //     // box-shadow: 0px 3px 11px 0px rgba(79,79,79,0.5);
                //     border-radius: 7px;
                //     color: #fff;
                // }
            }
            .table-box {
                min-height: 600px;
                border: 1px solid #eee;
            }
            /deep/ .el-table {
                .el-table__header-wrapper {
                    .el-table__header {
                        width: 100% !important;
                    }
                    tr {
                        th {
                            background: #f2f2f2;
                            font-size: 14px;
                            font-weight: 400;
                            color: #602666;
                        }
                    }
                }
                .el-table__body-wrapper .el-table__body{
                    tr {
                        td {
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                }
                .el-table__cell {
                    padding: 12px 0;
                }
                .el-table__row:hover {
                    td {
                        background: #f2f2f2 !important;
                    }
                }
            }
            /deep/ .el-pagination {
                margin-top: 20px;
                margin-right: 20px;
                text-align: right;
                padding: 0px;
                // .el-pager {
                //     .number.active {
                //         background: linear-gradient(72deg, #A766A0, #026FB8);
                //     }
                // }
                .btn-next {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>